/* eslint-disable camelcase */
import { useContext } from 'react';

import { createClientEvent } from '@buzzfeed/client-event-tracking';
import {
  ab_test,
  addressability,
  content_action,
  external_link,
  impression,
  internal_link,
  pageview,
  time_spent,
  videoview
} from '@buzzfeed/client-event-tracking/events';
import { createClientTimeSpentHandler, createClientClickHandler, createClientImpressionHandler } from '@buzzfeed/client-event-tracking/handlers'
import { trackPerformance as performanceTracking } from '@buzzfeed/performance';
import { TrackingContext, SubunitTrackingContext } from '../../contexts';
import { CLUSTER } from '../../constants';

const sendClientEvent = createClientEvent({
  env: CLUSTER,
  source: 'web_bf',
});

const createClientEventWithCommonData = event_config => (...layers) => {
  sendClientEvent(event_config, ...layers);
};

const trackClientImpression = createClientEventWithCommonData(impression);
const trackClientExternalLink = createClientEventWithCommonData(external_link);
const trackClientInternalLink = createClientEventWithCommonData(internal_link);
const trackClientContentAction = createClientEventWithCommonData(
  content_action
);

const attachClientInternalLinkHandler = createClientClickHandler(
  ({ layers }, ...additional_args) => {
    trackClientInternalLink(...additional_args, ...layers);
  }
);

const attachClientExternalLinkHandler = createClientClickHandler(
  ({ layers }, ...additional_args) => {
    trackClientExternalLink(...additional_args, ...layers);
  }
);

const attachClientContentAction = createClientClickHandler(
  ({ layers }, ...additional_args) => {
    trackClientContentAction(...additional_args, ...layers);
  }
);

const attachClientImpressionHandler = createClientImpressionHandler(
  trackClientImpression
);

const trackClientAddressability = createClientEventWithCommonData(addressability);
const trackClientVideoView = createClientEventWithCommonData(videoview);
const trackClientPageview = createClientEventWithCommonData(pageview);
const trackClientTimeSpent = createClientEventWithCommonData(time_spent);
const trackClientExperimentActive = createClientEventWithCommonData(ab_test);

const initializeClientTimeHandler = createClientTimeSpentHandler(
    ({ layers }, ...additional_args) => {
      trackClientTimeSpent(...additional_args, ...layers);
    }
);

const trackClientPerformance = (...layers) => {
  performanceTracking(sendClientEvent, {
    layers,
    /**
     * By default, performance vents are sampled at 10%. Increasing to 100% during the rollout to
     * ensure we have enough data to use for comparing.
     * @TODO remove once homepage is 100% rolled out
     */
    sample_rate: 1,
  });
};

export const useTrackingContext = () => {
  const pageInfo = useContext(TrackingContext);
  const subunitInfo = useContext(SubunitTrackingContext);

  const withPageInfo = trackingAction => eventInfo =>
    trackingAction({ ...pageInfo, ...subunitInfo, ...eventInfo });
  // Allow eventInfo to override the contexts ^

  const withPageInfoClient = trackingAction => (element, data) =>
    trackingAction(element, { ...pageInfo, ...subunitInfo }, data);


  return {
    pageInfo: pageInfo,
    trackAddressability: withPageInfo(trackClientAddressability),
    trackImpression: withPageInfo(trackClientImpression),
    trackExternalLink: withPageInfo(trackClientExternalLink),
    trackInternalLink: withPageInfo(trackClientInternalLink),
    trackContentAction: withPageInfo(trackClientContentAction),
    trackPageview: withPageInfo(trackClientPageview),
    trackExperimentActive: withPageInfo(trackClientExperimentActive),
    trackPerformance: withPageInfo(trackClientPerformance),
    trackTimeSpent: withPageInfo(initializeClientTimeHandler),
    trackClientInternalLinkHandler: withPageInfoClient(attachClientInternalLinkHandler),
    trackClientExternalLinkHandler: withPageInfoClient(attachClientExternalLinkHandler),
    trackClientContentAction: withPageInfoClient(attachClientContentAction),
    trackClientImpressionHandler: withPageInfoClient(attachClientImpressionHandler),
    trackClientVideoView: withPageInfo(trackClientVideoView),
    pageInfo
  };
};
